import { saveLog } from "@/modules/logger";
import { timestamp } from "@/utils/date";
import Recorder from "./lib/Recorder";
import { isIOS } from "@/utils/system";
import { RECORD_CONSTRAINS, IS_DEV } from "@/constants";

const audio = Boolean(isIOS) || RECORD_CONSTRAINS;
let audioCtx;

class AudioRecorder {
  reinit = false;

  constructor() {
    if (!AudioRecorder.instance) {
      AudioRecorder.instance = this;
    }
    return AudioRecorder.instance;
  }

  async initialUserMedia() {
    this.logs = {};
    console.log("IN initial user media, session id:", this.session_id);
    if (!audioCtx) {
      audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      window.audioCtx = audioCtx;

      this.setAudioCtxEventHandler();
    }

    if (!this.stream) {
      saveLog("mic-request");

      this.stream = await navigator.mediaDevices.getUserMedia({ audio });
      this.tracks = this.stream.getAudioTracks();

      saveLog("mic-granted");

      window.stream = this.stream;
      window.tracks = this.tracks;

      this.setStreamEventHandler();
      this.setTrackEventHandler();
      this.removeDefaultAudioSettings();
    }

    var pathname = window.location.pathname.split("/");
    this.prompt_name = pathname[pathname.length - 1];

    this.input = await audioCtx.createMediaStreamSource(this.stream);
    IS_DEV && console.log("Initialized stream source");

    if (audioCtx.state !== "running") {
      await audioCtx.resume();
    }
  }

  setStreamEventHandler() {
    this.stream.onaddtrack = (event) => {
      this.logs.stream_track_add = {
        timestamp: timestamp(),
        kind: event.track.kind,
      };
    };

    this.stream.onremovetrack = (event) => {
      this.logs.stream_track_remove = {
        timestamp: timestamp(),
        kind: event.track.kind,
      };
    };
  }

  setAudioCtxEventHandler() {
    audioCtx.onstatechange = () => {
      this.logs[`audio_context_state_${audioCtx.state}`] = {
        timestamp: timestamp(),
      };
    };
  }

  setTrackEventHandler() {
    this.tracks.forEach((track) => {
      this.logTrackState(track);

      track.onended = () => {
        this.logs.stream_track_end = {
          timestamp: timestamp(),
        };
      };

      track.onmute = () => {
        this.logs.stream_track_mute = {
          timestamp: timestamp(),
        };
      };

      track.onunmute = () => {
        this.logs.stream_track_unmute = {
          timestamp: timestamp(),
        };
      };
    });
  }

  logTrackState(track) {
    this.logs[`stream_track_state_${track.readyState}`] = {
      timestamp: timestamp(),
      enabled: track.enabled,
    };
  }

  removeDefaultAudioSettings() {
    this.tracks.forEach((track) => {
      track.applyConstraints(
        Object.assign(track.getSettings(), RECORD_CONSTRAINS)
      );
    });
  }

  update_terminated_buffer = async (buffers) => {
    IS_DEV && console.log("Recieved buffers from terminated recorder node");
    this.recorder.worker.postMessage({
      command: "record",
      buffer: buffers,
    });
  };

  register_lost_stream_popup(lost_stream_popup) {
    this.lost_stream_popup = lost_stream_popup;
  }

  register_rtmessage_handler(handler) {
    this.rtmessage_handler = handler;
  }

  register_wavuri_handler(handler) {
    this.wavuri_handler = handler;
  }

  register_set_stream_connection(handler) {
    this.set_streamer_connection = handler;
  }

  rtloststreamhandler_ = async (buffers, recLength, silence1, silence2) => {
    saveLog("rt-silence", {
        reinit1: this.reinit,
        silence1: silence1,
        silence2: silence2
      });
    return;
    // if (!isIOS) {
    //   saveLog("non-iOS-rtsilence", {msg: "Returning from lost stream handler without re-init"})
    //   return
    // }
    // this.recorder.stop();
    // this.input.disconnect();
    // this.tracks.forEach((track) => {
    //   track.stop();
    //   this.logTrackState(track);
    // });

    // if (this.reinit == false) {
    //   this.reinit = Date.now();
    // } else {
    //   saveLog("multi-rt-silence", {
    //     reinit1: this.reinit,
    //     reinit2: Date.now(),
    //   });
    //   if (isIOS) {
    //     saveLog("multi-rt-silence-popup");
    //     this.stop();
    //     this.lost_stream_popup();
    //   }
    // }

    // saveLog("rthandler-mic-requested");
    // this.stream = await navigator.mediaDevices.getUserMedia({ audio });
    // this.tracks = this.stream.getAudioTracks();
    // saveLog("rthandler-mic-granted");

    // window.stream = this.stream;
    // window.tracks = this.tracks;

    // this.setStreamEventHandler();
    // this.setTrackEventHandler();
    // this.removeDefaultAudioSettings();

    // this.input = audioCtx.createMediaStreamSource(this.stream);

    // IS_DEV &&
    //   console.log(
    //     `In RTLostStreamHandler_, recieved buffers. channel count: ${buffers.length}`
    //   );
    // IS_DEV && console.log(`Num samples: ${buffers[0].length}`);
    // IS_DEV && console.log(`This reinit: ${this.reinit}`);

    // this.recorder = new Recorder(this.input, this);
    // this.recorder.worker.postMessage({
    //   command: "setBuffer",
    //   buffer: buffers,
    //   recLength: recLength,
    // });

    // this.recorder.record();
  };

  async pause() {
    if (!this.recording) {
      saveLog("pause-not-recording")
      return
    }
    if (!this.recorder.recording) {
      saveLog("pause-already-paused")
    }
    this.recorder.stop()
    this.recorder.pause_audio_socket_message({"pause": true})
    saveLog("pause-success")
  }

  async resume() {
    if (!this.recording) {
      saveLog("resume-not-recording")
      return
    }
    if (this.recorder.recording) {
      saveLog("resume-already-recording")
    }
    this.recorder.record()
    this.recorder.pause_audio_socket_message({"pause": false})
    saveLog("resume-success")
  }

  async start() {
    await this.initialUserMedia();
    IS_DEV && console.log(`Starting recording`);
    this.reinit = false;
    this.recording = true;
    this.recorder = new Recorder(this.input, this);
    IS_DEV && console.log("Initialized recorder");
    IS_DEV && console.log(this.input);

    // return this.recorder.record(this.rtloststreamhandler.bind(this));
    this.start_timestamp = Date.now();
    return this.recorder.record();
  }

  async stop() {
    IS_DEV && console.log("index: In stop");
    this.stop_timestamp = Date.now();
    this.recording = false;
    this.recorder.disconnect_streamer();
    this.recorder.stop();
    IS_DEV && console.log("index: Recorder stopped");
    this.tracks.forEach((track) => {
      track.stop();
      this.logTrackState(track);
    });
    IS_DEV && console.log("index: tracks stopped");

    const data = await this.exportWAV();
    IS_DEV && console.log("index: data exported");
    const logs = { ...this.logs };

    this.clear();
    IS_DEV && console.log("index: this cleared");

    const reinit = this.reinit;
    var recordedDuration =
      (this.stop_timestamp - this.start_timestamp) / 1000.0;

    IS_DEV && console.log(`index: reinit , ${reinit}, ${this.reinit}`);
    IS_DEV && console.log({ ...data, logs, reinit, recordedDuration });

    return { ...data, logs, reinit, recordedDuration };
  }

  exportWAV() {
    IS_DEV && console.log("index: in export wav");
    return new Promise((resolve) => this.recorder.exportWAV(resolve));
  }

  clear() {
    this.recorder.clear();
    this.stream = null;
    this.recorder = null;
    this.input.disconnect();
    // this.silence_detector.disconnect()
    this.input = null;
    this.logs = null;
  }
}

export default AudioRecorder;
