import { mapActions, mapState } from 'vuex';
import { scrolltop } from '@/modules/utils';
// import { userDevice } from '@/utils/system';
// import { timestamp } from '@/utils/date';
import { saveLog } from '@/modules/logger';
import { formatAudioFileName } from "@/utils/format";

import { STORE_SEND_VOICE, RECORD_FILE_DATA } from '@/constants';

/**
 * property data should contains:
 * @param {voiceKey<String>}: key in database like "audio1"
 * @param {setPassed<Function>}: mixin to route changing
 * @param {type<String>}: type of recording "calibrate" or "record"
 */
export default {
  computed: {
    ...mapState({
      loading: 'loading',
      audioSource: 'audioSource',
      recording: 'recording',
      userid: ({ user }) => user.user_id,
      sessionid: ({ user }) => user.session_id
    }),

    fileName() {
      const { key } = RECORD_FILE_DATA[this.voiceKey];
      if (this.$store.state.uploading[this.voiceKey].name != null){
        saveLog("mixin",
          {msg : `Tried getting filename for ${this.voiceKey},\
          already set to ${this.$store.state.uploading[this.voiceKey].name}`});
        return this.$store.state.uploading[this.voiceKey].name;
      }
      saveLog("mixin", {msg : `Tried getting filename for ${this.voiceKey}, not set, creating new timestamp now.`});
      // return `${this.userid}/${timestamp()}-device_${userDevice()}-${key}.wav`;
      return formatAudioFileName(this.userid, this.sessionid, key);
    }
  },

  methods: {
    ...mapActions([STORE_SEND_VOICE]),

    stopRecordingHandler() {
      const top = document.documentElement.scrollHeight - window.innerHeight;
      scrolltop(top);
    },

    sendHandler(blob) {
      this.sendVoice(blob);
      this.setPassed();
    },

    sendVoice(blob) {
      const size = (blob.size / (1024 * 1024)).toFixed(2);
      const filename = this.fileName;
      saveLog("mixin-sendVoice", {msg: `In call to sendVoice for ${filename}`})

      saveLog('record-save', {
        name: filename,
        size: `${size} MB`
      });

      return this[STORE_SEND_VOICE]({
        blob,
        name: filename,
        key: this.voiceKey
      })
        .then(() => {
          saveLog('record-save-successful', {
            fileName: filename,
            key: this.voiceKey
          });
        })
        .catch(({ message }) => saveLog('record-save-error', { message }));
    }
  }
};
