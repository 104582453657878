import { saveLog } from '@/modules/logger';
import { STREAM, AWS_CREDENTIALS, RECORD_FILE_DATA  } from '@/constants';
import { formatAudioFileName } from '@/utils/format';
import {  getStorage } from "@/modules/localStorage";

const {
  STREAM_BUFFER_SIZE,
  VUE_APP_WS_STREAM_URL,
  STREAM_API_URL,
  STREAM_APP_ID
} = STREAM;

const AWS_BUCKET_NAME = AWS_CREDENTIALS.AWS_ALBUM_BUCKET_NAME;

const WS_URL = VUE_APP_WS_STREAM_URL;
const CONNECTION_STATE = {
  OPEN: 1
};

class Streamer {
  connected = false;
  send_buffer = [];
  parent = null;
  msg_handler = null;
  buffer_idx = 0;
  buf_size = STREAM_BUFFER_SIZE;
  AUDIO_BUFFER = new Float32Array(this.buf_size);
  AUDIO_BUF_IDX = 0;
  buffer_list = [];
  signal = [];
  s3_key = null;

  createResultSocket(prompt, session_id, user_id) {
    this.result_websocket = new WebSocket(WS_URL + 'streamer/result-stream');
    this.result_websocket.onerror = (event) => {
      console.log(event);
      saveLog("result-ws-error", {event});
      // this.parent.set_stremer_connection = false;
      this.parent.agg_received = null; // error state
    };
    this.result_websocket.onopen = () => {
      console.log('Opened result socket');
      this.result_websocket.send(
        JSON.stringify({
          prompt: prompt,
          SID: session_id,
          UserID: user_id,
          AppID: STREAM_APP_ID,
          result: true
        })
      );
      this.result_websocket.onmessage = event => {
        var data = JSON.parse(event.data);
        if (data.connected) {
          console.log('Conencted to result socket!');
          this.result_websocket.onmessage = resevent => {
            console.log(resevent.data);
            // handler(resevent.data);
            this.msg_handler(resevent.data);
          };
        } else {
          console.log('Failed to conenct to result socket');
          // handler('Failed to conenct to result socket');
          console.log(event.data);
        }
      };
    };
  }

  constructor(
    parent,
    numChannels,
    sampleRate,
    prompt,
    session_id,
    user_id,
    msg_handler,
    uri_handler
  ) {
    console.log('Initializing streamer');
    console.log('User ID :' + user_id);
    console.log('VUE_APP_WS_STREAM_URL: ' + VUE_APP_WS_STREAM_URL);
    console.log('WS_URL: ' + WS_URL);
    console.log('REST_URL: ' + STREAM_API_URL);
    // const { key } = RECORD_FILE_DATA[prompt];
    console.log(RECORD_FILE_DATA);
    this.parent = parent;
    this.s3_key = formatAudioFileName(user_id, session_id, RECORD_FILE_DATA[this.parent.voiceKey].key);
    this.parent.save_s3_key(this.s3_key);
    saveLog("streamer-s3", {msg: `Created s3 key in streamer: ${this.s3_key}`});
    console.log("S3 KEY: " + this.s3_key);
    saveLog('streamer-socket-constructor');
    console.log(this.parent.voiceKey);
    this.prompt = prompt;
    this.msg_handler = msg_handler;
    this.uri_handler = uri_handler;
    this.numChannels = numChannels;
    this.sampleRate = sampleRate;
    this.user_id = user_id;
    this.session_id = session_id;

    // Websocket constructor

    console.log(`In Streamer init, ${this.parent.StreamRegistrationPromise}`);
    // console.log(this.parent.StreamRegistrationPromise.resolved);

    const stream_registration_status = getStorage("stream-registration-status");
    console.log(`Stream registration status: ${stream_registration_status}`);

    if(this.parent.StreamerRegistered == null || stream_registration_status == false) {
      // FAILED REGISTRATION FOR STREAM SESSION, NEVER GOING TO CONNECT TO STREAM API FOR THIS SESSION
      saveLog("stream-registration-failure", { msg : "in Streamer.js, failed to register" });
      return;
    }

    if(!this.parent.StreamerRegistered && stream_registration_status == null) {
      console.log("in Streamer: parent registration is false, attaching ws connect as callback");
      saveLog("attach-ws-connect-cb-to-pending-registration");
      this.parent.StreamRegistrationPromise.then((e) => {
        if (e.status == 200)
          saveLog("ws-connect-cb-in-registration");
          this.create_websocket();
      });
    }
    else {
      console.log("in Streamer: parent registration is true, creating websockets now");
      console.log(`ASSERT ${stream_registration_status} is true`);
      if (stream_registration_status != true)
        alert("Previously opened websockets with API, but state says registration never succeeded. CONTACT DEV!")
      saveLog("stream-registration-complete");
      this.create_websocket();
    }

  }

  create_websocket() {
    console.log("STREAM REGISTRATION COMPLETE!");
    this.websocket = new WebSocket(WS_URL + 'streamer/audio-stream');
    this.websocket.onclose = (event) => {
      console.log(event);
      saveLog("ws-close", {event});
      // this.parent.set_streamer_connection(false);
    };
    this.websocket.onerror = (event) => {
      console.log(event);
      saveLog("ws-error", {event});
      this.parent.set_streamer_connection(false);
      this.parent.agg_received = null; // error state
    };
    this.websocket.onopen = () => {
      saveLog('streamer-socket-open');
      this.websocket.send(
        JSON.stringify({
          numChannels: this.numChannels,
          sampleRate: this.sampleRate,
          prompt: this.prompt,
          SID: this.session_id,
          UserID: this.user_id,
          AppID: STREAM_APP_ID,
          bucket_name: AWS_BUCKET_NAME,
          key: this.s3_key,
          result: false
        })
      );
      this.websocket.onmessage = event => {
        var data = JSON.parse(event.data);
        if (data.connected) {
          saveLog("stream-socket-connected");
          this.connected = true;
          this.parent.set_streamer_connection(true);
          console.log('Connected audio streamer');
          // this.msg_handler('Connected to streamer');
          this.createResultSocket(this.prompt, this.session_id, this.user_id);

          // Loop over all buffers to send
          while (this.buffer_list.length > 0) {
            var msg = this.buffer_list.shift() // 'pop' first buffer from list
            this.websocket.send(JSON.stringify(msg));
            console.log(`Sending WS buf ${msg.buffer_idx}`);
          }

        } else {
          console.log('Error', event.data);
          saveLog('streamer-socket-error');
          // this.msg_handler('FAILED to connect to streamer');
          this.parent.set_streamer_connection = false;
        }
        this.websocket.onmessage = event => {
          var data = JSON.parse(event.data);
          if ("error" in data){
            alert(data["error"]);
            saveLog("stream-error-received", {data});
            this.parent.set_streamer_connection(false);
            this.parent.agg_received = null; // error state
          }
          // this.msg_handler(event.data);
        };
      };
    };
  }
  get_ready_state() {
    return (
      this.connected &&
      this.websocket.readyState === CONNECTION_STATE.OPEN &&
      this.result_websocket.readyState === CONNECTION_STATE.OPEN
    );
  }

  process_breath_button_press(timestamp) {
    try {
      this.websocket.send(JSON.stringify({timestamp}));
    }
    catch{
      saveLog("failed-send-breath-timestamp-stream")
    }
  }

  process_buffer(buffer) {
    // Input: buffer FloatArray
    // Loop to:
    //  Fill rest of this.AUDIO_BUFFER:
    //      calculate buf_to_add
    //      set AUDIO_BUFFER from this.AUDIO_BUF_IDX
    //  if overlap:
    //      slice out remainder of buffer to do again
    //  if this.AUDIO_BUF_IDX == this.buf_len:
    //      send buffer and this.buffer_idx
    //      reset this.AUDIO_BUF_IDX
    //      increment this.buffer_idx
    //  else: BREAK

    var done = false;
    while (!done) {
      if (buffer.length == 0) {
        // console.log("Empty buffer encountered, exiting process buffer");
        done = true;
        break;
      }
      var buf_to_add = this.buf_size - this.AUDIO_BUF_IDX;
      var overflow_flag = false;
      if (buf_to_add > buffer.length) {
        // Audio received from Recorder is not sufficient to fill the send buffer
        // console.log("In process buffer: Audio received from Recorder is not sufficient to \
        // fill the send buffer");
        buf_to_add = buffer.length;
      } else {
        // Audio received from Recorder is more than enough to fill the send buffer
        // console.log("In process buffer: Audio received from Recorder is sufficient to \
        // fill the send buffer");
        overflow_flag = true;
      }

      // console.log(`BUF IDX : ${this.AUDIO_BUF_IDX}, buf to add : ${buf_to_add}`)
      this.AUDIO_BUFFER.set(buffer.slice(0, buf_to_add), this.AUDIO_BUF_IDX);
      this.AUDIO_BUF_IDX += buf_to_add;
      // console.log(`NEW BUF IDX : ${this.AUDIO_BUF_IDX}`)

      if (overflow_flag) {
        buffer = buffer.slice(buf_to_add); // sets buffer to only the portion not set in AUDIO_BUFFER
      }

      if (this.AUDIO_BUF_IDX >= this.buf_size) {
        // console.log(`Sending buffer on websocket, idx : ${this.buffer_idx}`)
        // console.assert(this.AUDIO_BUF_IDX == this.buf_size);
        var send_buffer = {
          buffer: Array.from(this.AUDIO_BUFFER),
          buffer_idx: this.buffer_idx
        };
        if (!this.connected) {
          this.buffer_list.push(send_buffer);
          // console.log("Received buf before connnection to stream server; adding to queue");
        }
        else {
          this.websocket.send(JSON.stringify(send_buffer));
          // console.log(`Sending WS buf ${send_buffer.buffer_idx}`);
        }
        // console.log(`Done sending buffer on websocket, idx : ${this.buffer_idx}`)

        this.AUDIO_BUF_IDX = 0;
        this.buffer_idx++;
        if (!overflow_flag) {
          // console.log("No overlap after sending, breaking now")
          done = true;
          break;
        }
      } else {
        // NOTHING TO DO NOW
        done = true; // only to satisfy the 'no-constant-condition' constraint
        break;
      }
    }
    // console.log("COMPLETED PROCESS BUFFER CALL")
  }

  disconnect() {
    if (!this.connected) {
      console.log("streamer disconnect called, but never connected");
      this.parent.StreamerRegistered = null;
      return;
    }
    var sig_sum = 0;
    var sig_min = null;
    var sig_max = null;
    var sig_len = this.signal.length;
    this.signal.forEach(function (item) {
      sig_sum += item;
      if (sig_min == null || item < sig_min)
        sig_min = item;
      if (sig_max == null || item > sig_max)
        sig_max = item;
    });

    console.log(`Streamer.js: in disconnect, audio buf idx : ${this.AUDIO_BUF_IDX} `);
    console.log(`Length of audio buffer: ${this.AUDIO_BUFFER.length}`);

    // Send pending data (will be < BUF_SIZE)
    var send_buffer = {
      buffer: Array.from(this.AUDIO_BUFFER.slice(0,this.AUDIO_BUF_IDX)),
      buffer_idx: this.buffer_idx
    }
    this.websocket.send(JSON.stringify(send_buffer));
    console.log(`Sending LAST WS buf ${send_buffer.buffer_idx}`);

    while (this.buffer_list.length > 0) {
      var msg = this.buffer_list.shift() // 'pop' first buffer from list
      console.log("sending pending audio bufs before disconnect" )
      this.websocket.send(JSON.stringify(msg));
      console.log(`Sending WS buf ${msg.buffer_idx}`);
    }

    console.log({sig_sum, sig_min, sig_max});
    this.websocket.send(JSON.stringify({disconnect: true, stats : {sig_sum, sig_min, sig_max, sig_len} }))

    this.websocket.onmessage = event => {
      var data = JSON.parse(event.data);
      console.log(data)
      var audio_src = STREAM_API_URL + "review-audio/" + data.WAVID;
      this.uri_handler(audio_src, data.duration, this.s3_key);
    }
  }

  process_audio(buffer_dict) {
    if ('buffer' in buffer_dict) {
      // add to signal
      this.signal.push.apply(this.signal, buffer_dict['buffer']);
      this.process_buffer(buffer_dict['buffer']);
    } else {
      // this.websocket.send(JSON.stringify(buffer_dict));
      saveLog("process-audio-error", {buffer_dict});
    }
  }

  process_message(message_dict) {
    // send message_dict on websocket
    try {
      this.websocket.send(JSON.stringify(message_dict));
    }
    catch{
      saveLog("failed-process-message")
    }
  }

  close() {
    this.websocket.close();
    this.result_websocket.close();
  }
}

export default Streamer;
