<template>
  <div :class="{ page__outside: iOS }">
    <v-header />
    <div class="container container_sm page__container">
      <card v-if="$slots.default">
        <slot />
      </card>
      <card v-if="$slots.text">
        <slot name="text" />
      </card>
      <slot v-if="$slots.space" name="space" />
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header";
import Card from "@/components/card";
import { isIOS } from "@/utils/system";

export default {
  components: { vHeader, Card },

  data: () => ({
    iOS: isIOS,
  }),
};
</script>
