import InlineWorker from 'inline-worker';
import { saveLog } from '@/modules/logger';
import Streamer from './Streamer.js';
import { RECORD_TYPE } from '@/constants';
import { isAndroid } from '@/utils/system';

export class Recorder {
  config = {
    bufferLen: isAndroid ? 4096 : 2048,
    numChannels: 2,
    mimeType: RECORD_TYPE
  };

  recording = false;

  callbacks = {
    getBuffer: [],
    exportWAV: []
  };

  constructor(source, parent, streamer_obj, cfg) {
    Object.assign(this.config, cfg);
    console.log('Constructing Recorder');
    this.beginning = true;
    this.context = source.context;

    this.parent = parent;
    this.contig_sil = 0;
    this.terminated = false;
    this.source = source;
    console.log('Recorder in prompt ', this.parent.prompt_name);
    // console.log("Recorder.js: ", this.parent.isCalibration());

    // if (this.parent.isCalibration()) {
    //   console.log("Recorder : Initializing Streamer");
    //   try {
    //     this.streamer = new Streamer(this.config.numChannels, this.context.sampleRate, "silence.wav");
    //     console.log("Succesfully initialized streamer");
    //     saveLog("streamer-init");
    //   }
    //   catch (error) {
    //     console.log("Failed to initialize streamer", error);
    //     saveLog("streamer-init-error", {init_error: error});
    //   }
    // }

    if (!streamer_obj) {
      console.log('Recorder : Initializing Streamer');
      try {
        this.streamer = new Streamer(
          this.parent,
          this.config.numChannels,
          this.context.sampleRate,
          this.parent.prompt_name,
          this.parent.session_id,
          this.parent.user_id,
          this.parent.rtmessage_handler,
          this.parent.wavuri_handler
        );
        console.log('Succesfully initialized streamer');
        saveLog('streamer-init');

        this.streamer.onmessage = e => {
          if (e.data.command == 'StreamResults') {
            alert(e.data.results);
          }
        };
      } catch (error) {
        console.log('Failed to initialize streamer', error);
        saveLog('streamer-init-error', { init_error: error });
      }
    }
    else {
      console.log('Recorder: Using existing streamer object');
      this.streamer = streamer_obj

    }

    this.node = (
      this.context.createScriptProcessor || this.context.createJavaScriptNode
    ).call(
      this.context,
      this.config.bufferLen,
      this.config.numChannels,
      this.config.numChannels
    );

    this.node.onaudioprocess = e => {
      if (!this.recording) {
        return;
      }
      var buffer = [];
      var channel = 0;

      var check_silence_buf = e.inputBuffer.getChannelData(0);
      for (var i = 0; i < check_silence_buf.length; i++) {
        if (check_silence_buf[i] == 0) {
          if (!this.beginning) this.contig_sil++;
        } else {
          this.beginning = false;
          this.contig_sil = 0;
        }
      }

      if (this.worker && !this.beginning && this.contig_sil > 1920) {
        this.worker.postMessage({
          command: 'rtloststream'
        });
        // this.source.disconnect();
        return;
      }

      for (channel = 0; channel < this.config.numChannels; channel++) {
        buffer.push(e.inputBuffer.getChannelData(channel));
      }

      // if (this.parent.isCalibration()) {
      //   console.log("Streaming buffer", typeof(check_silence_buf));
      //   this.streamer.process_audio({buffer: Array.from(check_silence_buf)});
      // }
      try {
        // if (this.streamer.get_ready_state()) {
        //   this.streamer.process_audio({
        //     buffer: Array.from(check_silence_buf)
        //   });
        this.streamer.process_audio({
            buffer: Array.from(check_silence_buf)
        });
      } catch (error) {
        console.log(error);
        console.log('Failed to send audio by websockets');
      }
      // console.log("script node: Sending record message to worker")
      this.worker.postMessage({
        command: 'record',
        buffer: buffer
      });
    };

    this.source.connect(this.node);
    this.node.connect(this.context.destination); //this should not be necessary

    let self = {};
    this.worker = new InlineWorker(function() {
      let beginning = true;
      let recLength = 0;
      let silence = 0; // count of 0s in buffer
      let silence2 = 0; // count of 0s that belong to subsequence of at least 2 non-leading 0's
      let rtsilence = 0; // real-time count of contiguous 0's
      let recBuffers = [];
      let sampleRate;
      let numChannels;

      this.onmessage = function(e) {
        switch (e.data.command) {
          case 'init':
            init(e.data.config);
            break;
          case 'record':
            record(e.data.buffer);
            break;
          case 'exportWAV':
            exportWAV(e.data.type);
            break;
          case 'getBuffer':
            getBuffer();
            break;
          case 'setBuffer':
            setBuffers(e.data.buffer, e.data.recLength);
            break;
          case 'rtloststream':
            this.postMessage({
              command: 'RTLostStream',
              data: {
                buffers: recBuffers,
                recLength: recLength,
                silence1: calculatePercentOfSilence(silence),
                silence2: calculatePercentOfSilence(silence2)
              }
            });

            break;
          case 'clear':
            clear();
            break;
        }
      };

      function init(config) {
        sampleRate = config.sampleRate;
        numChannels = config.numChannels;
        initBuffers();
      }

      function setBuffers(buffer, reclen) {
        // console.log("InLineWorker: In setBuffers");
        recBuffers = buffer;
        recLength = reclen;

        // console.log(`Length of buffer: ${recBuffers[0].length}`);
        // console.log(recLength);
      }

      function record(inputBuffer) {
        var rtfailure = false;
        for (let channel = 0; channel < numChannels; channel++) {
          if (channel === 0) {
            for (let i = 0, len = inputBuffer[channel].length; i < len; i++) {
              if (inputBuffer[channel][i] === 0) {
                silence += 1;
                rtsilence += 1;
                if (!beginning && inputBuffer[channel][i - 1] === 0) {
                  silence2 += 1;
                }
                if (!beginning && rtsilence > 2000) {
                  rtfailure = true;
                }
              } else {
                beginning = false;
                rtsilence = 0;
              }
            }
          }
          recBuffers[channel].push(inputBuffer[channel]);
        }
        recLength += inputBuffer[0].length;
        if (rtfailure) {
          // this.postMessage({
          //     command: "RTLostStream",
          //     data: {
          //       buffers: recBuffers,
          //       recLength: recLength
          //     }
          // });
        }
      }

      function exportWAV(type) {
        let buffers = [];
        for (let channel = 0; channel < numChannels; channel++) {
          buffers.push(mergeBuffers(recBuffers[channel], recLength));
        }
        let interleaved;
        if (numChannels === 2) {
          interleaved = interleave(buffers[0], buffers[1]);
        } else {
          interleaved = buffers[0];
        }
        let dataview = encodeWAV(interleaved);
        let audioBlob = new Blob([dataview], { type: type });

        this.postMessage({
          command: 'exportWAV',
          data: {
            blob: audioBlob,
            percentOfSilenceIOs: calculatePercentOfSilence(silence2),
            percentOfSilenceAllDevices: calculatePercentOfSilence(silence),
            samples: recLength
          }
        });
      }

      function calculatePercentOfSilence(num) {
        return recLength
          ? parseFloat(((num * 100) / recLength).toFixed(1), 10)
          : 0;
      }

      function getBuffer() {
        let buffers = [];
        for (let channel = 0; channel < numChannels; channel++) {
          buffers.push(mergeBuffers(recBuffers[channel], recLength));
        }
        this.postMessage({ command: 'getBuffer', data: buffers });
      }

      function clear() {
        recLength = 0;
        recBuffers = [];
        initBuffers();
      }

      function initBuffers() {
        for (let channel = 0; channel < numChannels; channel++) {
          recBuffers[channel] = [];
        }
      }

      function mergeBuffers(recBuffers, recLength) {
        let result = new Float32Array(recLength);
        let offset = 0;
        for (let i = 0; i < recBuffers.length; i++) {
          result.set(recBuffers[i], offset);
          offset += recBuffers[i].length;
        }
        return result;
      }

      function interleave(inputL, inputR) {
        let length = inputL.length + inputR.length;
        let result = new Float32Array(length);

        let index = 0,
          inputIndex = 0;

        while (index < length) {
          result[index++] = inputL[inputIndex];
          result[index++] = inputR[inputIndex];
          inputIndex++;
        }
        return result;
      }

      function floatTo16BitPCM(output, offset, input) {
        for (let i = 0; i < input.length; i++, offset += 2) {
          let s = Math.max(-1, Math.min(1, input[i]));
          output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
        }
      }

      function writeString(view, offset, string) {
        for (let i = 0; i < string.length; i++) {
          view.setUint8(offset + i, string.charCodeAt(i));
        }
      }

      function encodeWAV(samples) {
        // saveLog("export-wav", {msg: "entered-worker"});
        // console.log("inlineworker: Inside encodeWAV");
        // console.log(`samples: ${samples.length}`);
        let buffer = new ArrayBuffer(44 + samples.length * 2);
        let view = new DataView(buffer);

        /* RIFF identifier */
        writeString(view, 0, 'RIFF');
        // saveLog("export-wav", {msg: "Add RIFF ID"});
        /* RIFF chunk length */
        view.setUint32(4, 36 + samples.length * 2, true);
        // saveLog("export-wav", {msg: "Add RIFF length"});
        /* RIFF type */
        writeString(view, 8, 'WAVE');
        // saveLog("export-wav", {msg: "Add RIFF type"});
        /* format chunk identifier */
        writeString(view, 12, 'fmt ');
        /* format chunk length */
        view.setUint32(16, 16, true);
        /* sample format (raw) */
        view.setUint16(20, 1, true);
        /* channel count */
        view.setUint16(22, numChannels, true);
        // saveLog("export-wav", {msg: "Add RIFF num channels"});
        /* sample rate */
        view.setUint32(24, sampleRate, true);
        // saveLog("export-wav", {msg: "Add RIFF sample rate"});
        /* byte rate (sample rate * block align) */
        view.setUint32(28, sampleRate * 4, true);
        /* block align (channel count * bytes per sample) */
        view.setUint16(32, numChannels * 2, true);
        /* bits per sample */
        view.setUint16(34, 16, true);
        // saveLog("export-wav", {msg: "Add wav chunks"});
        /* data chunk identifier */
        writeString(view, 36, 'data');
        /* data chunk length */
        view.setUint32(40, samples.length * 2, true);
        // saveLog("export-wav", {msg: "Convert to PCM"});

        floatTo16BitPCM(view, 44, samples);
        // saveLog("export-wav", {msg: "worker-done"});

        return view;
      }
    }, self);

    this.worker.postMessage({
      command: 'init',
      config: {
        sampleRate: this.context.sampleRate,
        numChannels: this.config.numChannels
      }
    });

    this.worker.onmessage = e => {
      if (e.data.command == 'RTLostStream') {
        // this.recording = false;  // if need to stop, parent.rtloststream will call stop() which sets this to false

        this.parent.rtloststreamhandler_(
          e.data.data.buffers,
          e.data.data.recLength,
          e.data.data.silence1,
          e.data.data.silence2
        );

        // this.terminated = true;
        return;
      }
      let cb = this.callbacks[e.data.command].pop();
      if (typeof cb == 'function') {
        cb(e.data.data);
      }
    };
  }

  record() {
    this.recording = true;
  }

  disconnect_streamer() {
    console.log('Disconnecting from streaming server');
    try {
      // this.streamer.process_audio({ disconnect: true });
      this.streamer.disconnect()
    } catch (error) {
      this.streamer.close();
      console.log('Failed to disconnect websockets');
    }

  }

  stop() {
    this.recording = false;
  }

  pause_audio_socket_message(pause_dict) {
    this.streamer.process_message(pause_dict);
  }

  clear() {
    this.worker.postMessage({ command: 'clear' });
    this.worker.terminate();
  }

  getBuffer(cb) {
    cb = cb || this.config.callback;
    if (!cb) throw new Error('Callback not set');

    this.callbacks.getBuffer.push(cb);

    this.worker.postMessage({ command: 'getBuffer' });
  }

  exportWAV(cb, mimeType) {
    // console.log("Recorder.js: export wav");
    mimeType = mimeType || this.config.mimeType;
    cb = cb || this.config.callback;
    if (!cb) throw new Error('Callback not set');

    this.callbacks.exportWAV.push(cb);

    this.worker.postMessage({
      command: 'exportWAV',
      type: mimeType
    });
  }

  static forceDownload(blob, filename) {
    let url = (window.URL || window.webkitURL).createObjectURL(blob);
    let link = window.document.createElement('a');
    link.href = url;
    link.download = filename || 'output.wav';
    let click = document.createEvent('Event');
    click.initEvent('click', true, true);
    link.dispatchEvent(click);
  }
}

export default Recorder;
